
if (window.location.pathname.startsWith("/contact.html")) {

document.addEventListener("DOMContentLoaded", function () {
    const submitButton = document.querySelector("#submit");
    
    if (submitButton) {
        submitButton.addEventListener("click", function (event) {
            event.preventDefault(); // Prevent default form submission
            let isValid = true;

            // Get input elements
            const firstName = document.getElementById("first-name");
            const lastName = document.getElementById("last-name");
            const phone = document.getElementById("phone");
            const email = document.getElementById("email");
            const message = document.querySelector("textarea");

            // Get error message elements
            const errorName = document.getElementById("errorname");
            const errorLastName = document.getElementById("errorlastname");
            const errorPhone = document.getElementById("errorphone");
            const errorEmail = document.getElementById("erroremail");

            // Reset errors
            errorName?.classList.add("hidden");
            errorLastName?.classList.add("hidden");
            errorPhone?.classList.add("hidden");
            errorEmail?.classList.add("hidden");

            // Validate First Name
            if (firstName.value.trim() === "") {
                errorName?.classList.remove("hidden");
                isValid = false;
            }

            // Validate Last Name
            if (lastName.value.trim() === "") {
                errorLastName?.classList.remove("hidden");
                isValid = false;
            }

            // Validate Phone Number
            if (phone.value.trim() === "" || !/^[0-9]{10}$/.test(phone.value.trim())) {
                errorPhone?.classList.remove("hidden");
                isValid = false;
            }

            // Validate Email
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email.value.trim() === "" || !emailPattern.test(email.value.trim())) {
                errorEmail?.classList.remove("hidden");
                isValid = false;
            }

            // Validate Message
            if (message.value.trim() === "") {
                // alert("Please enter your message.");
                isValid = false;
            }

            // Submit form if valid
            if (isValid) {
                // alert("Form submitted successfully!");
                document.querySelector("form").submit();
            }
        });
    } else {
        console.error("Submit button not found!");
    }
});

}
