if (window.location.pathname === '/'){
const roles = ["Full Stack Developer", "Security Engineer", "IOT Engineer", "Mobile App Developer", "DevOps Engineer"];
let roleIndex = 0;
const textElement = document.getElementById("text-role");
const typingSpeed = 30; // Faster typing speed
const deletingSpeed = 10; // Faster deletion speed
const transitionDelay = 100; // Delay between finishing deletion and starting new word

function typeWord(word) {
  textElement.innerHTML = ''; // Clear existing content

  word.split('').forEach((letter, index) => {
    // Create a span for each letter, including spaces
    const letterSpan = document.createElement('span');
    letterSpan.textContent = letter === ' ' ? '\u00A0' : letter; // Use non-breaking space for spaces
    textElement.appendChild(letterSpan);
    
    // Show each letter with a delay for the typing effect
    setTimeout(() => {
      letterSpan.classList.add('show');
    }, typingSpeed * index);
  });
}

function deleteWord(callback) {
  const spans = textElement.querySelectorAll('span');
  spans.forEach((span, index) => {
    setTimeout(() => {
      span.classList.remove('show'); // Fade out letters faster
      if (index === spans.length - 1) {
        setTimeout(callback, transitionDelay); // Call the callback when done
      }
    }, deletingSpeed * index);
  });
}

function cycleRoles() {
  const word = roles[roleIndex];

  typeWord(word); // Type the word
  setTimeout(() => {
    deleteWord(() => {
      roleIndex = (roleIndex + 1) % roles.length; // Move to the next word
      cycleRoles(); // Start typing the next word immediately after deletion
    });
  }, word.length * typingSpeed + 2000); // Start deletion after typing
}

// Start typing the first word on page load
cycleRoles();
}