    // Get menu items and sections
    const contactMenu = document.getElementById('contact-menu');
    const resourcesMenu = document.getElementById('resources-menu');
    const dynamicIsland = document.getElementById('dynamic-island');
    const contactSection = document.getElementById('contact-section');
    const resourcesSection = document.getElementById('resources-section');
    var isInRange = window.innerWidth > 640;
    var isInRange1 = window.innerWidth > 820;

    // Function to handle hover logic with customizable width and height
    function handleHover(sectionToShow, width, height) {
      // Expand the dynamic island and change its appearance based on the hover item
      dynamicIsland.style.width = width;
      dynamicIsland.style.height = height;
      dynamicIsland.style.borderRadius = '50px';

      // Hide all sections first
      contactSection.classList.remove('visible');
      resourcesSection.classList.remove('visible');

      // Show the correct section
      sectionToShow.classList.add('visible');
    };
    // contactMenu.addEventListener('mouseenter', () => handleHover(contactSection, '620px', '500px'));
    // resourcesMenu.addEventListener('mouseenter', () => handleHover(resourcesSection, '800px', '500px'));
    // Function to reset the island size, styles, and hide sections
    function resetIsland() {
      if (isInRange){
        dynamicIsland.style.width = '470px';
      } else {
        dynamicIsland.style.width = '370px';
      }
      dynamicIsland.style.height = '55px';
      dynamicIsland.style.borderRadius = '50px'; // Original border radius

      // Hide all sections
      contactSection.classList.remove('visible');
      resourcesSection.classList.remove('visible');
    };


    const isOutRange = window.innerWidth > 571;
    if(isInRange){
      contactMenu.addEventListener('mouseenter', () => handleHover(contactSection, '620px', '470px'));
      
    } else {
      contactMenu.addEventListener('mouseenter', () => handleHover(contactSection, '96%', '320px'));
        
    }
    if (isInRange1){
      resourcesMenu.addEventListener('mouseenter', () => handleHover(resourcesSection, '800px', '470px'));
    } else {
      resourcesMenu.addEventListener('mouseenter', () => handleHover(resourcesSection, '96%', '320px'));
    }
   

    function isTouchDevice() {
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }
    if (isTouchDevice()) {
      if (isInRange){
        contactMenu.addEventListener('click', () => handleHover(contactSection, '620px', '470px'));
      } else{
        contactMenu.addEventListener('click', () => handleHover(contactSection, '96%', '320px'));
      }

      if (isInRange1){
        resourcesMenu.addEventListener('click', () => handleHover(resourcesSection, '800px', '470px'));
      } else {
        resourcesMenu.addEventListener('click', () => handleHover(resourcesSection, '96%', '320px'));
      }

    }


    // Add mouseleave event to reset the dynamic island when not hovering over it
    dynamicIsland.addEventListener('mouseleave', resetIsland);
    document.addEventListener('click', (event) => {
      if (!dynamicIsland.contains(event.target) &&
        !contactMenu.contains(event.target) &&
        !resourcesMenu.contains(event.target)) {
        resetIsland();
      }
    });

    // Function to download a PDF file
function downloadPDF() {
  const pdfUrl = '../../Dhanush-Resume.pdf'; // Replace with the actual PDF URL
  const link = document.createElement('a');
  link.href = pdfUrl;
  link.download = 'Dhanush-Resume.pdf'; // Change filename if needed
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// Add event listener to button
document.getElementById('resume').addEventListener('click', downloadPDF);
