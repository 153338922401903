// Variables for drag-to-close
let startY = 0;
let currentY = 0;
let isDragging = false;
const dragThreshold = 100; // pixels to drag down before closing

// Function to open modal
function openModal(e) {
  resetIsland()
  const targetModalId = e.currentTarget.getAttribute('data-modal');
  const modal = document.getElementById(targetModalId);
  const modalContent = modal.querySelector('.modal-content');
  const videoElement = modal.querySelector('video');
  // modal.scrollTop = 0;
  
  // Update scrollPosition when opening the modal
  let scrollPosition = window.scrollY || document.documentElement.scrollTop;

  // Show the modal container
  modal.classList.remove('hidden');

  // Disable background scroll: fix the body and set overflow hidden
  document.body.style.overflow = 'hidden';
  // document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.left = '0';
  document.body.style.right = '0';

  // Trigger the slide-up animation
  setTimeout(() => {
    modalContent.classList.remove('translate-y-full');
    modalContent.classList.add('translate-y-0');
  }, 10);

  // Play the video only once
  if (videoElement) {
    videoElement.playbackRate = 1.5;
    videoElement.play();
  }

  // Set up the close button dynamically for this modal
  const closeButton = modal.querySelector('.close-modal');
  closeButton.addEventListener('click', () => closeModal(modal, scrollPosition, 200), { once: true });
}

// Function to close modal
function closeModal(modal, scrollPosition, time = 10) {
  const modalContent = modal.querySelector('.modal-content');
  const videoElement = modal.querySelector('video');

  // Animate slide-down via CSS classes
  modalContent.classList.add('translate-y-full');
  modalContent.classList.remove('translate-y-0');

  // Stop video and reset it when modal closes
  if (videoElement) {
    videoElement.pause();
    videoElement.currentTime = 0; // Reset to start
  }

  // After the animation completes (300ms), hide the modal and restore scrolling
  setTimeout(() => {
    modal.classList.add('hidden');

    // Restore body's styles and scroll position
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.right = '';
    window.scrollTo(0, scrollPosition);
  }, time);
}

// Add drag-to-close functionality using pointer events
function addDragToClose(modal) {
  const modalContent = modal.querySelector('.modal-content');
  let startY = 0;
  let currentY = 0;
  let isDragging = false;
  const dragThreshold = 100; // pixels threshold for close

  // pointerdown: start drag only if at top and not clicking a close button
  modalContent.addEventListener(
    'pointerdown',
    (e) => {
      // Only start if content is at the very top and not on a close button
      if (modalContent.scrollTop > 0 || e.target.classList.contains('close-modal')) {
        return;
      }
      // Prevent native scrolling
      e.preventDefault();

      startY = e.clientY;
      isDragging = true;
      // Disable native touch actions during drag so scrolling doesn’t kick in
      modalContent.style.touchAction = 'none';
      modalContent.setPointerCapture(e.pointerId);
    },
    { passive: false }
  );

  // pointermove: drag the modal content down
  modalContent.addEventListener(
    'pointermove',
    (e) => {
      if (!isDragging) return;
      // Prevent default scrolling behavior while dragging
      e.preventDefault();

      currentY = e.clientY;
      const deltaY = currentY - startY;
      if (deltaY > 0) {
        modalContent.style.transform = `translateY(${deltaY}px)`;
      }
    },
    { passive: false }
  );

  // pointerup: decide whether to close the modal or snap back
  modalContent.addEventListener('pointerup', (e) => {
    if (!isDragging) return;
    isDragging = false;
    // Restore touch action so normal scrolling resumes
    modalContent.style.touchAction = '';
    const deltaY = currentY - startY;

    if (deltaY > dragThreshold) {
      // Drag exceeded threshold: animate off-screen then close
      modalContent.style.transition = 'transform 0.3s';
      modalContent.style.transform = 'translateY(100%)';
      setTimeout(() => {
        modalContent.style.transition = '';
        modalContent.style.transform = '';
        closeModal(modal, window.scrollY);
      }, 300);
    } else {
      // Otherwise, snap back to original position
      modalContent.style.transition = 'transform 0.3s';
      modalContent.style.transform = 'translateY(0)';
      setTimeout(() => {
        modalContent.style.transition = '';
        modalContent.style.transform = '';
      }, 300);
    }
  });

  // pointercancel: in case the gesture is interrupted
  modalContent.addEventListener('pointercancel', (e) => {
    isDragging = false;
    modalContent.style.touchAction = '';
    modalContent.style.transition = 'transform 0.3s';
    modalContent.style.transform = 'translateY(0)';
    setTimeout(() => {
      modalContent.style.transition = '';
      modalContent.style.transform = '';
    }, 300);
  });
}


// Open modal when a card is clicked
document.querySelectorAll('.card').forEach((card) => {
  card.addEventListener('click', openModal);
});

// Close modal when clicking outside of modal content (i.e., on the overlay)
document.addEventListener('click', (event) => {
  const modal = event.target.closest('.modal');
  const modalContent = modal ? modal.querySelector('.modal-content') : null;

  if (modal && !modalContent.contains(event.target)) {
    closeModal(modal, window.scrollY, 300);
  }
});

// Initialize all modals with drag-to-close functionality
document.querySelectorAll('.modal').forEach((modal) => {
  addDragToClose(modal);
});
