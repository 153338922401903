if (window.location.pathname === '/'){
function start3DGridAnimation() {
    const canvas = document.getElementById('rectangleCanvas');
    const ctx = canvas.getContext('2d');
    const shadowDiv = document.getElementById('shadowBox');

    // Enable image smoothing.
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Set initial canvas dimensions.
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Outer (front face) and inner (back face) rectangles.
    let outerWidth  = canvas.width;
    let outerHeight = canvas.height;
    let innerWidth  = outerWidth * 0.5;
    let innerHeight = outerHeight * 0.5;
    let offsetX = (outerWidth - innerWidth) / 2;
    let offsetY = (outerHeight - innerHeight) / 2;

    const outerRect = {
      topLeft:     { x: 0,            y: 0 },
      topRight:    { x: outerWidth,   y: 0 },
      bottomLeft:  { x: 0,            y: outerHeight },
      bottomRight: { x: outerWidth,   y: outerHeight }
    };

    const innerRect = {
      topLeft:     { x: offsetX,               y: offsetY               },
      topRight:    { x: outerWidth - offsetX,  y: offsetY               },
      bottomLeft:  { x: offsetX,               y: outerHeight - offsetY },
      bottomRight: { x: outerWidth - offsetX,  y: outerHeight - offsetY }
    };

    // Set the shadow DIV to match the inner rectangle.
    function updateShadowBox() {
      shadowDiv.style.left = offsetX + 'px';
      shadowDiv.style.top = offsetY + 'px';
      shadowDiv.style.width = innerWidth + 'px';
      shadowDiv.style.height = innerHeight + 'px';
    }
    updateShadowBox();

    // --- Grid Parameters and Responsive Handling ---
    let gridSpacing = 300; // default grid spacing
    let cols = Math.ceil(outerWidth / gridSpacing);
    let rows = Math.ceil(outerHeight / gridSpacing);
    const speed = 2;
    let offset = 0;

    // When the canvas is small, use a smaller grid spacing (and thus fewer columns).
    function updateGridParams() {
      if (canvas.width < 600) {
        gridSpacing = canvas.width / 3; // force about 3 columns
      } else {
        gridSpacing = 300;
      }
      cols = Math.ceil(outerWidth / gridSpacing);
      rows = Math.ceil(outerHeight / gridSpacing);
    }
    updateGridParams();

    // --- Cell Data Creation ---
    // When creating a cell, we store its original column index.
    function createImg(url, col) {
      const im = new Image();
      im.src = url;
      return { img: im, originalCol: col, col: col };
    }

    // Example cells for the bottom face.
    let bottomCells = [
      { baseRow: -2, ...createImg('https://ik.imagekit.io/dhanush/portfolio/image(1).png', 2) },
      { baseRow: -1, ...createImg('https://ik.imagekit.io/dhanush/portfolio/image.png', 5) },
      { baseRow: 0,  ...createImg('https://ik.imagekit.io/dhanush/portfolio/Screenshot%20from%202025-02-01%2012-32-33.png', 1) },
      { baseRow: 1,  ...createImg('https://ik.imagekit.io/dhanush/portfolio/Screenshot%20from%202025-02-01%2012-32-23.png', 3) }
    ];

    // Example cells for the top face.
    let topCells = [
      { baseRow: -2, ...createImg('https://ik.imagekit.io/dhanush/portfolio/Screenshot%20from%202025-02-01%2012-34-28.png', 2) },
      { baseRow: -2, ...createImg('https://ik.imagekit.io/dhanush/portfolio/Screenshot%20from%202025-02-01%2012-32-33.png', 5) },
      { baseRow: 0,  ...createImg('https://ik.imagekit.io/dhanush/portfolio/image.png', 1) },
      { baseRow: 0,  ...createImg('https://ik.imagekit.io/dhanush/portfolio/image(1).png', 4) }
    ];

    // --- Update Cell Mapping for Responsive Grid ---
    // If the new number of columns (cols) is less than the original,
    // then for any cell with an originalCol >= cols, assign a new column index (randomly among 0..cols-1).
    function updateCellMapping(cellArray) {
      cellArray.forEach(cell => {
        if (cell.originalCol >= cols) {
          cell.col = Math.floor(Math.random() * cols);
        } else {
          cell.col = cell.originalCol;
        }
      });
    }
    // Do this once initially.
    updateCellMapping(bottomCells);
    updateCellMapping(topCells);

    // ------------------------------------------------------------
    // 1) Helper: linear interpolation between two points.
    // ------------------------------------------------------------
    function interpolate(p1, p2, t) {
      return {
        x: p1.x + (p2.x - p1.x) * t,
        y: p1.y + (p2.y - p1.y) * t
      };
    }

    function drawLine(start, end) {
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.stroke();
    }

    // ------------------------------------------------------------
    // 2) Draw a grid on a given quadrilateral face.
    // ------------------------------------------------------------
    function drawGrid(topLeft, topRight, bottomLeft, bottomRight) {
      ctx.strokeStyle = 'rgba(255,255,255,0.4)';
      ctx.lineWidth = 1;
      // Vertical lines.
      for (let c = 0; c <= cols; c++) {
        const t = c / cols;
        const start = interpolate(topLeft, topRight, t);
        const end = interpolate(bottomLeft, bottomRight, t);
        drawLine(start, end);
      }
      // Horizontal lines.
      const lowerBound = -offset / gridSpacing - 1;
      const upperBound = rows - offset / gridSpacing;
      const startR = Math.floor(lowerBound);
      const endR = Math.ceil(upperBound);
      for (let r = startR; r <= endR; r++) {
        const t = (r + offset / gridSpacing) / rows;
        if (t >= -1 && t <= 1) {
          const start = interpolate(topLeft, bottomLeft, t);
          const end = interpolate(topRight, bottomRight, t);
          drawLine(start, end);
        }
      }
    }

    // ------------------------------------------------------------
    // 3) Draw Image in Parallelogram with Clipping.
    // Stretch the image to fill the cell and clip it to the cell’s shape.
    // ------------------------------------------------------------
    function drawImageInParallelogramClipped(ctx, img, cellTopLeft, cellTopRight, cellBottomRight, cellBottomLeft) {
      if (!img.complete || img.naturalWidth === 0) return;
      
      ctx.save();
      
      // Create clipping path matching the cell quadrilateral.
      ctx.beginPath();
      ctx.moveTo(cellTopLeft.x, cellTopLeft.y);
      ctx.lineTo(cellTopRight.x, cellTopRight.y);
      ctx.lineTo(cellBottomRight.x, cellBottomRight.y);
      ctx.lineTo(cellBottomLeft.x, cellBottomLeft.y);
      ctx.closePath();
      ctx.clip();

      // Compute vectors from cell's top-left.
      const v1 = {
        x: cellTopRight.x - cellTopLeft.x,
        y: cellTopRight.y - cellTopLeft.y
      };
      const v2 = {
        x: cellBottomLeft.x - cellTopLeft.x,
        y: cellBottomLeft.y - cellTopLeft.y
      };

      // Set up the affine transform to map:
      // (0,0) -> cellTopLeft, (img.width,0) -> cellTopRight, (0,img.height) -> cellBottomLeft.
      const a = v1.x / img.width;
      const b = v1.y / img.width;
      const c = v2.x / img.height;
      const d = v2.y / img.height;
      const e = cellTopLeft.x;
      const f = cellTopLeft.y;
      
      ctx.setTransform(a, b, c, d, e, f);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
    }

    // ------------------------------------------------------------
    // 4) Fill Cells: Compute each cell's four corners and draw the image.
    // ------------------------------------------------------------
    function fillCells(topLeft, topRight, bottomLeft, bottomRight, cellArray) {
      for (let item of cellArray) {
        let effRow = item.baseRow + offset / gridSpacing;
        if (effRow > rows) {
          item.baseRow -= (rows + 1);
        }
        const newEffRow = item.baseRow + offset / gridSpacing;
        const tTop = newEffRow / rows;
        const tBottom = (newEffRow + 1) / rows;
        if (tBottom < -1 || tTop > 1) continue;
        let alpha = 1.0;
        if (tTop < 0) {
          alpha = tTop + 1;
          if (alpha < 0) alpha = 0;
        }
        ctx.save();
        ctx.globalAlpha = alpha;

        const cLeft  = item.col / cols;
        const cRight = (item.col + 1) / cols;
        const cellTopLeft = interpolate(
          interpolate(topLeft, topRight, cLeft),
          interpolate(bottomLeft, bottomRight, cLeft),
          tTop
        );
        const cellTopRight = interpolate(
          interpolate(topLeft, topRight, cRight),
          interpolate(bottomLeft, bottomRight, cRight),
          tTop
        );
        const cellBottomLeft = interpolate(
          interpolate(topLeft, topRight, cLeft),
          interpolate(bottomLeft, bottomRight, cLeft),
          tBottom
        );
        const cellBottomRight = interpolate(
          interpolate(topLeft, topRight, cRight),
          interpolate(bottomLeft, bottomRight, cRight),
          tBottom
        );

        // Draw the image in the cell.
        drawImageInParallelogramClipped(ctx, item.img, cellTopLeft, cellTopRight, cellBottomRight, cellBottomLeft);
        ctx.restore();
      }
    }

    // ------------------------------------------------------------
    // 5) Main Animation Loop.
    // ------------------------------------------------------------
    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw 3D box edges.
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 1;
      // Bottom face.
      drawGrid(outerRect.bottomLeft, outerRect.bottomRight, innerRect.bottomLeft, innerRect.bottomRight);
      fillCells(outerRect.bottomLeft, outerRect.bottomRight, innerRect.bottomLeft, innerRect.bottomRight, bottomCells);
      // Top face.
      drawGrid(outerRect.topLeft, outerRect.topRight, innerRect.topLeft, innerRect.topRight);
      fillCells(outerRect.topLeft, outerRect.topRight, innerRect.topLeft, innerRect.topRight, topCells);
      // Left face.
      drawGrid(outerRect.topLeft, outerRect.bottomLeft, innerRect.topLeft, innerRect.bottomLeft);
      // Right face.
      drawGrid(outerRect.topRight, outerRect.bottomRight, innerRect.topRight, innerRect.bottomRight);
      
      // (Optional) Fill inner rectangle with black.
      ctx.fillStyle = 'rgb(5 8 13)';
      ctx.fillRect(innerRect.topLeft.x, innerRect.topLeft.y, innerWidth, innerHeight);
      
      offset += speed;
      requestAnimationFrame(draw);
    }

    draw();

    // On window resize, update dimensions, grid parameters, and remap cells.
    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      outerWidth = canvas.width;
      outerHeight = canvas.height;
      innerWidth = outerWidth * 0.5;
      innerHeight = outerHeight * 0.5;
      offsetX = (outerWidth - innerWidth) / 2;
      offsetY = (outerHeight - innerHeight) / 2;
      outerRect.topRight.x = outerWidth;
      outerRect.bottomLeft.y = outerHeight;
      outerRect.bottomRight.x = outerWidth;
      outerRect.bottomRight.y = outerHeight;
      innerRect.topLeft.x = offsetX;
      innerRect.topLeft.y = offsetY;
      innerRect.topRight.x = outerWidth - offsetX;
      innerRect.topRight.y = offsetY;
      innerRect.bottomLeft.x = offsetX;
      innerRect.bottomLeft.y = outerHeight - offsetY;
      innerRect.bottomRight.x = outerWidth - offsetX;
      innerRect.bottomRight.y = outerHeight - offsetY;

      updateShadowBox();
      updateGridParams();
      // Update cell mapping for responsive grid.
      updateCellMapping(bottomCells);
      updateCellMapping(topCells);
    });
  }
  start3DGridAnimation();
  }